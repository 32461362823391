import React from 'react'

import Link from 'next/link'

import {
  Body2,
  CloudinaryImage,
  Layout,
  Spacer,
  TitleLarge2,
  TitleMedium2,
  TitleSmall2,
} from 'ethos-design-system'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import { IMAGE_SIZES } from '../../constants'
import { CardsArrow } from '../../icons.js'
import styles from './Cards.module.scss'

interface CardsProps {
  moduleData: Record<string, any>
}

/**
 * Cards Module on the frontend.
 *
 * @param {object} moduleData - Results of the CardsQuery
 *
 * @returns {ReactNode}
 */
const Cards: React.FC<CardsProps> = ({ moduleData }) => {
  const { heading, enableH1, displaySubHeading, subHeading, imageType, cards } =
    moduleData

  const CARD_COUNTS = [3, 4, 6]
  if (!CARD_COUNTS.includes(cards.length)) {
    throw new Error(
      `Invalid number of cards! Use ${CARD_COUNTS.join(' / ')} cards only!`
    )
  }

  const headingElement = enableH1 ? 'h1' : 'div'
  const Headings = () => (
    <div className={styles.headings}>
      <TitleLarge2.Serif.Book500 element={headingElement}>
        {heading}
      </TitleLarge2.Serif.Book500>
      {displaySubHeading && subHeading && (
        <>
          <Spacer.H8 />
          <TitleMedium2.Sans.Regular400>
            {subHeading}
          </TitleMedium2.Sans.Regular400>
        </>
      )}
    </div>
  )

  const Image = ({ source, alt }: { source: number; alt: string }) => {
    let widths: Array<number> = []
    let heights: Array<number> = []
    if (imageType === IMAGE_SIZES.LARGE) {
      widths = [327, 213, 400, 400]
      heights = [154, 159, 229, 229]
    } else if (imageType === IMAGE_SIZES.SMALL) {
      widths = [56, 56, 56, 56]
      heights = [56, 56, 56, 56]
    }
    return (
      <CloudinaryImage
        publicId={source}
        width={widths}
        height={heights}
        alt={alt}
        className={[styles.image, styles[imageType]].join(' ')}
      />
    )
  }

  const Card = ({ data }: Record<string, any>) => {
    const hasImage =
      imageType === IMAGE_SIZES.LARGE && data.groupImage && data.imageAlt
    const isSmallImage =
      imageType === IMAGE_SIZES.SMALL && data.groupImage && data.imageAlt

    const hasCta = data.displayCta && data.ctaLabel && data.clickthroughUrl
    const alignmentSpacing = (hasCta && !hasImage) || (hasCta && hasImage)
    const borderStyles = [styles.border, styles[imageType]]
    if (alignmentSpacing) {
      borderStyles.push(styles.flex)
    }

    return (
      <div className={styles.card}>
        {hasImage && <Image source={data.groupImage} alt={data.imageAlt} />}
        <div className={borderStyles.join(' ')}>
          {isSmallImage && (
            <Image source={data.groupImage} alt={data.imageAlt} />
          )}
          <div className={[styles.cardCopy, styles[imageType]].join(' ')}>
            {data.groupTitle && (
              <TitleSmall2.Serif.Book500>
                {data.groupTitle}
              </TitleSmall2.Serif.Book500>
            )}
            {data.groupBodyCopy && (
              <div className={styles.bodyCopy}>
                <Body2.Regular400>{data.groupBodyCopy}</Body2.Regular400>
              </div>
            )}
          </div>
          {hasCta && (
            <div className={styles.cta}>
              <Body2.Regular400>{data.ctaLabel}</Body2.Regular400>
              <CardsArrow />
            </div>
          )}
        </div>
      </div>
    )
  }

  const CardsLayout = () => {
    let columns = 'thirds'
    if (cards.length === 4) {
      columns = 'fourths'
    }

    return (
      <div className={styles.row}>
        {cards.map((card: Record<string, any>, idx: number) => {
          const output = card.clickthroughUrl ? (
            <Link
              href={card.clickthroughUrl}
              onClick={() => {
                cmsModuleAnalytics.ctaClicked({
                  properties: {
                    ctaLabel: card.ctaLabel,
                    clickthroughUrl: card.clickthroughUrl,
                    buttonPosition: `Card ${idx + 1} / ${cards.length}`,
                    module: 'Cards',
                  },
                })
              }}
              key={idx}
              className={styles[columns]}
            >
              <Card data={card} />
            </Link>
          ) : (
            <Card data={card} className={styles[columns]} />
          )
          return output
        })}
      </div>
    )
  }

  // Output markup and React elements
  return (
    <div className={styles.cards}>
      <Layout.HorizontallyPaddedContainer>
        <Headings />
        <CardsLayout />
      </Layout.HorizontallyPaddedContainer>
    </div>
  )
}

export default Cards
